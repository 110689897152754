img.overlay-image {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

h1.overlay-name {
  position: absolute;
  bottom: 0%;
  color: white;
  margin: 10px;
}

.card-container {
  margin: 1em 0 1em 0;
}

.card-img-top {
  filter: brightness(85%);
}

.card-image {
  position: relative;
}

.card-attribute {
  margin: .5em 1em .5em 1em;
}