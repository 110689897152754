.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ebeffb;
}

/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../src/assets/twin.jpg");

  /* Set a specific height */
  height: 100vh;
  width: 100%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  text-align: left;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 4em;
  padding-right: 4em;
  max-width: 50vh;
  color: #ebeffb;
}

nav.nav-tabs {
  background-color: #4e5d74;
}

a.nav-item {
  background-color: #243448;
  color: #ebeffb;
  text-decoration: none;
}

.hero-text h5 {
  font-weight: normal;
}

.text-section-container {
  padding: 4em 2em 0;
  margin: auto;
}

#support-us .text-section-container {
  max-width: 600px;
}

#our-mission .text-section-container {
  padding: 4em 8em 0;
}

.donate-section {
  margin-top: 100px;
}

.donate-section .text-section-container {
  background-color: #2b3646;
  padding: 4em;
  max-width: 400px;
}

.donate-section .text-section {
  margin: unset;
}

.donate-section .text-section h3 {
  padding-bottom: 1em;
  font-weight: normal;
}

.text-section {
  text-align: left;
  margin-bottom: 4em;
}

.text-section h2 {
  margin-bottom: 2rem;
}

.footer-section {
  text-align: justify;
  text-align: center;
  padding: 2em;
}

button.btn.btn-sand {
  background-color: #cab9a0;
  color: white;
}

button.btn.btn-sand:hover {
  background-color: #b4a28a;
  color: white;
}

.font-bold {
  font-weight: 700;
}

.mb-50 {
  margin-bottom: 50px;
}

.dark-theme {
  background-color: #243448;
  color: #ebeffb;
}

.footer-section p {
  text-align: center;
  margin: 1em;
}

.container-fluid .row:last-child {
  padding-bottom: 0;
}

/* Smaller than standard 960 (devices and browsers) */
@media only screen and (max-width: 959px) {
}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
  .navbar {
    position: relative;
  }
  #home.row {
    padding-top: 0;
  }
  .hero-image {
    height: 100vh;
  }
  .hero-text {
    left: 3em;
    padding-right: 3em;
  }
  #our-mission .text-section-container {
    padding: 4em 2em 0;
  }
}

/* Landscape iPhone 6,7,8 */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  .hero-image {
    height: 100vh;
  }
  .hero-text {
    display: flex;
    max-width: unset;
  }
  .navbar {
    position: relative;
  }
  #home.row {
    padding-top: 0;
  }
}

/* Landscape iPhone XR*/
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .hero-image {
    height: 100vh;
  }
  .hero-text {
    display: flex;
    max-width: unset;
  }
  .navbar {
    position: relative;
  }
  #home.row {
    padding-top: 0;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .hero-image {
    height: 100vh;
  }
  .hero-text {
    display: flex;
    max-width: unset;
  }
  .navbar {
    position: relative;
  }
  #home.row {
    padding-top: 0;
  }
}

/* Landscape iPhone X */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: landscape) {
  .hero-image {
    height: 100vh;
  }
  .hero-text {
    display: flex;
    max-width: unset;
  }
  .navbar {
    position: relative;
  }
  #home.row {
    padding-top: 0;
  }
}

/* Landscape Android */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .hero-image {
    height: 100vh;
  }
  .hero-text {
    display: flex;
    max-width: unset;
  }
  .navbar {
    position: relative;
  }
  #home.row {
    padding-top: 0;
  }
}

/* Google Pixel */
@media only screen and (min-width: 731px) and (-webkit-min-device-pixel-ratio: 2.6) and (orientation: landscape) {
  .hero-image {
    height: 100vh;
  }
  .hero-text {
    display: flex;
    max-width: unset;
  }
  .navbar {
    position: relative;
  }
  #home.row {
    padding-top: 0;
  }
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {
}
