.grid-container {
  margin: 2em auto;
}

.rounded-circle {
  width: 100%;
  max-width: 150px;
}

.row {
  padding: 15px 0 15px 0;
}