.card-generator-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.btn-sand {
  background-color: #d3c1a7;
  color: white;
}

.btn-sand:hover {
  background-color: #b4a28a;
  color: white;
}